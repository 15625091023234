import { FilmstripValidationErrors } from '../error';
import { Background } from '../object';
import { $, Signal } from '../signal';
import { ControlPanelButton } from './control-panel-button';
import { FilmstripType } from './filmstrip.type';
import { Module } from './module.class';

export abstract class Filmstrip<M extends Module = Module> {
  /** 幻燈片的序號 */
  public index: number = 0;

  public isStart: boolean = false;

  public isEnd: boolean = false;

  /** 在編輯模式下，會出現在編輯區域下方提示創作者的文字 */
  public hit = $.computed<string | null>(() => null);

  /** 控制面板下面的按鈕 */
  public buttons: ControlPanelButton[] = [];

  public prevButtonName: string = '上一頁';

  public nextButtonName: string = '下一頁';

  /** 模組 */
  public module: M;

  /** 檢查該幻燈片是否有錯誤 */
  public error = $.computed<FilmstripValidationErrors>(() => {
    const error = this._processError();
    if (!error) return null;

    Object.entries(error).forEach(([key, value]) => {
      if (value) return;
      delete error[key];
    });

    if (Object.keys(error).length === 0) return null;
    return error;
  });

  /** 手機版選項 */
  public abstract readonly mobileToolbar: {
    options: string[];
    selectedOptionIndex: number;
  };

  /** 幻燈片的類型，用來載入編輯功能用 */
  public abstract readonly type: FilmstripType;

  /** 幻燈片名稱，會顯示在 sidebar 中 */
  public abstract name: string;

  /** 該環節是否有使用者互動行為？ */
  public abstract readonly withMobileInteractive: boolean;

  public abstract projection: { background: Background };

  public abstract mobile: { background: Background };

  public abstract data: {};

  public abstract _processError: Signal<FilmstripValidationErrors>;

  public toJSON() {
    return {
      index: this.index,
      type: this.type,
      name: this.name,
      projection: $.toJSON(this.projection),
      mobile: $.toJSON(this.mobile),
      data: $.toJSON(this.data),
    };
  }

  public fromJSON(json: any) {
    $.fromJSON(json, this);
  }
}

export type FilmstripJson<PROJECTION = any, MOBILE = any, DATA = any> = {
  index: number;
  type: string;
  name: string;
  projection: PROJECTION;
  mobile: MOBILE;
  data: DATA;
};
